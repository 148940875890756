import React, { useEffect, useState } from 'react'
import { useNavigate, useSearchParams } from 'react-router-dom'
import { queryrRecordInfoApi, mobileAlertInfoApi } from '../../../service/arams'
import { Toast, Input, Button, Form, TextArea, PasscodeInput, Picker } from 'antd-mobile'

const AramsDetail = () => {
    const history = useNavigate();
    const [search, setSearch] = useSearchParams()  // eslint-disable-line no-unused-vars
    const [form] = Form.useForm()
    const [recordStatusVisible, setRecordStatusVisible] = useState(false);
    const [dealStatusVisible, setDealStatusVisible] = useState(false);
    const [introductionVisible, setIntroductionVisible] = useState(false);
    const [beneficialLeverVisible, setBeneficialLeverVisible] = useState(false);
    const [recordStatusValue, setRecordStatusValue] = useState([]);
    const [recordStatusLabel, setRecordStatusLabel] = useState('');
    const [dealStatusValue, setDealStatusValue] = useState([]);
    const [dealStatusLabel, setDealStatusLabel] = useState('');
    const [introductionValue, setIntroductionValue] = useState([]);
    const [introductionLabel, setIntroductionLabel] = useState('');
    const [beneficialLeverValue, setBeneficialLeverValue] = useState([]);
    const [beneficialLeverLabel, setBeneficialLeverLabel] = useState('');


    const onRecordStatusChange = () => {
        setRecordStatusVisible(true);
    }
    const onCloseRecordStatusChange = () => {
        setRecordStatusVisible(false);
    }
    const onDealStatusChange = () => {
        setDealStatusVisible(true);
    }
    const onCloseDealStatusChange = () => {
        setDealStatusVisible(false);
    }
    const onIntroductionChange = () => {
        setIntroductionVisible(true);
    }
    const onCloseIntroductionChange = () => {
        setIntroductionVisible(false);
    }
    const onBeneficialLeverChange = () => {
        setBeneficialLeverVisible(true);
    }
    const onCloseBeneficialLeverChange = () => {
        setBeneficialLeverVisible(false);
    }

    const queryrRecordInfoData = () => {
        queryrRecordInfoApi({ "id": search.get('id') }).then(res => {
            Toast.clear()

            const data = res?.data;
            setRecordStatusValue(data?.recordStatus === null ? [] : [data?.recordStatus]);
            setRecordStatusLabel(data?.recordStatus !== null ? recordStatusColumns[0]?.[data?.recordStatus]?.label : '');
            setDealStatusValue(data?.dealStatus === null ? [] : [data?.dealStatus]);
            setDealStatusLabel(data?.dealStatus !== null ? (data?.dealStatus === 3 ? dealStatusColumns[0]?.[data?.dealStatus - 1]?.label : dealStatusColumns[0]?.[data?.dealStatus]?.label) : '');
            setIntroductionValue(data?.fromParty === null ? [] : [data?.fromParty]);
            setIntroductionLabel(data?.fromParty !== null ? (data?.fromParty === 10 ? introductionColumns[0]?.[5]?.label : introductionColumns[0]?.[data?.fromParty]?.label) : '');
            setBeneficialLeverValue(data?.incomeLevel === null ? [] : [data?.incomeLevel]);
            setBeneficialLeverLabel(data?.incomeLevel !== null ? beneficialLeverColumns[0]?.[data?.incomeLevel]?.label : '');

            form.setFieldsValue({
                "dealStatus": dealStatusValue || [],
                "recordStatus": recordStatusValue || [],
                "followLink": data?.followLink,
                "remark": data?.remark,
                "fromParty": introductionValue || [],
                "incomeLevel": beneficialLeverValue || [],
                "incomeMoney": data?.incomeMoney,
                "verifyCode": '',
            })
        }).catch(
            err => {
                Toast.show({
                    icon: 'fail',
                    content: '未知错误',
                })
                history('/')
            }
        )
    }


    useEffect(() => {

        try {
            Toast.show({
                icon: 'loading',
                duration: 0,
                content: '加载中…',
            })
            queryrRecordInfoData();
        } catch (e) {
            Toast.show({
                icon: 'fail',
                content: '未知错误',
            })
            history('/')
        }
    }, [])//eslint-disable-line 

    useEffect(() => {
        form.setFieldsValue({
            recordStatus: recordStatusValue,
            dealStatus: dealStatusValue,
            fromParty: introductionValue,
            incomeLevel: beneficialLeverValue,
        });
    }, [recordStatusValue, dealStatusValue, introductionValue, beneficialLeverValue, form]);

    const onFinish = (values) => {

        Toast.show({
            icon: 'loading',
            duration: 0,
            content: '加载中…',
        })

        mobileAlertInfoApi({
            "id": search.get('id'),
            "dealStatus": values.dealStatus,
            "recordStatus": values.recordStatus,
            "followLink": values.followLink,
            "remark": values.remark,
            "fromParty": values.fromParty,
            "incomeLevel": values.incomeLevel,
            "incomeMoney": values.incomeMoney,
            "verifyCode": values.verifyCode
        }).then(res => {
            if (res.code === 200) {
                Toast.clear()
                Toast.show({
                    icon: 'success',
                    content: '处理成功',
                })
            } else if (res.code === 100) {
                Toast.show({
                    icon: 'fail',
                    content: res.msg,
                });
            }
        });
    }


    const recordStatusColumns = [[
        { label: '待处理', value: 0 },
        { label: '处理中', value: 1 },
        { label: '已处理', value: 2 },
    ]]

    const dealStatusColumns = [[
        { label: '故障', value: 0 },
        { label: '预警', value: 1 },
        { label: '其他', value: 3 },
    ]]

    const introductionColumns = [[
        { label: '产品', value: 0 },
        { label: '研发', value: 1 },
        { label: '测试', value: 2 },
        { label: '三方', value: 3 },
        { label: '运营', value: 4 },
        { label: '其他', value: 10 },
    ]]

    const beneficialLeverColumns = [[
        { label: 'P0 (10000+)', value: 0 },
        { label: 'P1 (5000-10000)', value: 1 },
        { label: 'P2 (1000-5000)', value: 2 },
        { label: 'P3 (200-1000)', value: 3 },
        { label: 'P4 (200以下)', value: 4 },
    ]]

    return (
        <div className='topbackGroupClocl'>
            <Form layout='vertical'
                footer={
                    <Button block type='submit' color='primary' size='large'>
                        提交
                    </Button>}
                form={form}
                onFinish={onFinish}
            >
                <Form.Header>告警处理</Form.Header>
                <Form.Item label='状态' name='recordStatus' trigger='onConfirm' onClick={onRecordStatusChange} rules={[
                    { required: true, message: '状态不能为空' }]}
                >
                    <Input readOnly value={recordStatusLabel} placeholder='请选择状态' onClick={onRecordStatusChange} />
                    <Picker columns={recordStatusColumns} value={recordStatusValue} visible={recordStatusVisible} onCancel={onCloseRecordStatusChange}
                        onConfirm={v => {
                            setRecordStatusValue(v);
                            setRecordStatusLabel(recordStatusColumns[0]?.[v[0]]?.label);
                            form.setFieldValue({ recordStatus: v });
                            onCloseRecordStatusChange();
                        }
                        } />
                </Form.Item>
                <Form.Item label='结论' name='dealStatus' trigger='onConfirm' onClick={onDealStatusChange} rules={[
                    { required: true, message: '结论不能为空' }]}>
                    <Input readOnly value={dealStatusLabel} placeholder='请选择结论' onClick={onDealStatusChange} />
                    <Picker columns={dealStatusColumns} value={dealStatusValue} visible={dealStatusVisible} onClose={onCloseDealStatusChange}
                        onConfirm={v => {
                            setDealStatusValue(v);
                            setDealStatusLabel(v[0] === 3 ? dealStatusColumns[0]?.[v[0] - 1]?.label : dealStatusColumns[0]?.[v[0]]?.label);
                            form.setFieldValue({ dealStatus: v });
                            onCloseDealStatusChange();
                        }
                        } />
                </Form.Item>
                <Form.Item label='引入方' name='fromParty' trigger='onConfirm' onClick={onIntroductionChange} rules={[
                    { required: true, message: '引入方不能为空' }]}>
                    <Input readOnly value={introductionLabel} placeholder='请选择引入方' onClick={onIntroductionChange} />
                    <Picker columns={introductionColumns} value={introductionValue} visible={introductionVisible} onClose={onCloseIntroductionChange}
                        onConfirm={v => {
                            setIntroductionValue(v);
                            setIntroductionLabel(v[0] === 10 ? introductionColumns[0]?.[5]?.label : introductionColumns[0]?.[v[0]]?.label);
                            form.setFieldValue({ introduction: v });
                            onCloseIntroductionChange();
                        }} />
                </Form.Item>
                <Form.Item label='效益等级' name='incomeLevel' trigger='onConfirm' onClick={onBeneficialLeverChange} rules={[
                    { required: true, message: '效益等级不能为空' }]}>
                    <Input readOnly value={beneficialLeverLabel} placeholder='请选择效益等级' onClick={onBeneficialLeverChange} />
                    <Picker columns={beneficialLeverColumns} value={beneficialLeverValue} visible={beneficialLeverVisible} onClose={onCloseBeneficialLeverChange}
                        onConfirm={v => {
                            setBeneficialLeverValue(v);
                            setBeneficialLeverLabel(beneficialLeverColumns[0]?.[v[0]]?.label);
                            form.setFieldValue({ beneficialLever: v });
                            onCloseBeneficialLeverChange();
                        }} />
                </Form.Item>
                <Form.Item label='效益核算(元)' name='incomeMoney' rules={[
                    { required: true, message: '效益核算(元)不能为空' },
                    { pattern: /^-?\d+(\.\d+)?$/, message: '必须必须为数值' }
                ]}>
                    <Input type="number" />
                </Form.Item>
                <Form.Item label='跟踪' name='followLink' rules={[
                    { required: true, message: '跟踪不能为空' }]}>
                    <TextArea
                        placeholder='请输入跟踪'
                        maxLength={1000}
                        rows={2}
                        showCount
                    />
                </Form.Item>
                <Form.Item label='备注' name='remark'>
                    <TextArea
                        placeholder='请输入备注'
                        maxLength={1000}
                        rows={2}
                        showCount
                    />
                </Form.Item>
                <Form.Item label='密码' name='verifyCode' rules={[
                    { required: true, message: '密码不能为空' }]}>
                    <PasscodeInput length={4} />
                </Form.Item>
            </Form>

        </div >
    )
}

export default AramsDetail;