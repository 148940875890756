import axios from 'axios'
// import { getToken } from './service/login/auth'

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  timeout: 50000
})
// 全局请求拦截
instance.interceptors.request.use(function (config) {
  //token信息
  // config.headers['authorization']='Bearer '+getToken()
  return config;
}, function (error) {
  return Promise.reject(error);
});

// 请求返回之后拦截
instance.interceptors.response.use(function (response) {
  return response.data;
}, function (error) {

  return Promise.reject(error);
});

/**
 * get 请求
 * @param {*} url 
 * @param {*} params 
 * @returns 
 */
export function get(url, params) {
  return instance.get(url, {
    params
  })
}

export function post(url, data) {
  return instance.post(url, data)
}

export function postParam(url, params) {
  const queryString = new URLSearchParams(params).toString();
  return instance.post(url, queryString, {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
  })
}

export function postFile(url, data) {
  return instance.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  })
}

export function put(url, data) {
  return instance.put(url, data)
}

export function del(url) {
  return instance.delete(url)
}